<script setup>
	import {
		ref,
		computed,
		watch,
	} from 'vue';
	import Events from "shared/settings/events";
	import { DsHeader } from "@coloplast/design-system";
	import { useStore } from 'vuex';

	const props = defineProps({
		vm: {
			type: Object,
			default: () => { }
		},
	});

	const
		store = useStore(),
		hideBasketWhenEmpty = ref(props?.vm?.baseViewModel?.hideBasketWhenEmpty),
		navModel = computed(() => props?.vm?.baseViewModel),

		showSearchPopup = computed(() => store.getters.getShowSearchPopup),
		isSearchPage = computed(() => store.getters.getIsSearchPage),
		loggedIn = ref(props?.vm?.baseViewModel?.loggedIn),
		basketIsEnabled = ref(props?.vm?.baseViewModel?.enableBasket),
		basketItemsCount = ref(props?.vm?.baseViewModel?.basketCount),	
		numberOfBasketItems = computed(() => store.getters.getBasketItemCount),
		count = computed(() => {
			if (numberOfBasketItems?.value == 0) {
				return basketItemsCount.value;
			}
			else {
				return numberOfBasketItems.value;
			}
		});

	watch(
		() => isSearchPage.value,
		(newValue) => {
			if (newValue) {
				//if search page - disabled search icon in nav
				const searchButton = document?.getElementsByClassName('ds-header-search')?.[0];
				if (searchButton) {
					searchButton.disabled = true;
				}
			}
		}, { immediate: true })

	watch(
		() => count.value,
		(newValue) => {
			//if we need to hide basket when empty then rely on basket items count
			if (hideBasketWhenEmpty.value && props?.vm?.baseViewModel?.enableBasket) {
				//if empty && hide basket when empty
				if (newValue === 0) {
					basketIsEnabled.value = false;
				}
				else {
					basketIsEnabled.value = true;
				}
			}
		}, { immediate: true })

	watch(
		() => numberOfBasketItems.value,
		(newValue, oldValue) => {
			if (oldValue) {
				basketItemsCount.value = newValue;
			}
		}, { immediate: true })

	function triggerFlyoutBasket() {
		const openFlyoutBasket = new CustomEvent(Events.ACTIVATE_FLY_OUT_BASKET, {});
		document.dispatchEvent(openFlyoutBasket);
	}

	function toggleSearch() {
		store.commit('setShowSearchPopup', !showSearchPopup.value);
	}
</script>

<template>
	<DsHeader :cta-buttons="navModel.ctaButtons"
			  :labels="navModel.labels"
			  :logo="navModel.logo"
			  :loggedIn="loggedIn"
			  :enableBasket="basketIsEnabled"
			  :basketCount="count"
			  :enableSearch="navModel.enableSearch"
			  :searchFn="toggleSearch"
			  :enableSticky="navModel.enableSticky"
			  :navItems="navModel.navItems"
			  :serviceNavItems="navModel.serviceNavItems"
			  :basketFn="triggerFlyoutBasket">
		<!-- Static markup (for SEO) -->
		<slot></slot>
		<!--Global search desktop-->
		<template #searchDesktop v-if="navModel?.enableSearch">
			<slot name="search"></slot>
		</template>
		<!--Global search mobile-->
		<template #searchMobile v-if="navModel?.enableSearch">
			<slot name="search"></slot>
		</template>
	</DsHeader>
</template>

<style lang="scss">
	@import 'multisite/styles/settings/settings';
	@import 'multisite/styles/tools/tools';

	.ds-nav-mega-search, .ds-header-mobile__search {
		.ds-nav-mega-search__content {
			padding-block-start: 0;
		}

		.c-global-search__popup {
			all: unset;

			&-stickybox {
				padding: 0;

				&::after {
					all: unset;
				}
			}
		}

		.c-global-search-autosuggestions__box {
			margin-left: 0;
		}
	}

	.c-nav-service-design {
		z-index: 1;
	}

	/*for razor view (default slot)*/
	@media (min-width: ds-breakpoint('md')) {
		.ds-header-visible-mobile {
			display: none!important;
		}
	}

	@media (max-width: ds-breakpoint('md')) {
		.ds-header-visible-desktop {
			display: none!important;
		}
	}

	#global-nav-mount + .c-breadcrumb .c-breadcrumb__container {
		max-width: calc(ds-container-max('2xl') - ds-space(8));
	}
</style>

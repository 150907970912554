import { createApp } from 'vue';
import GlobalNav from "./globalNav.vue";
import GlobalSearchPopup from 'shared/components/globalSearch/globalSearchPopup.vue';
import "@coloplast/design-system/dist/components/header/ds-header.css";
import store from 'shared/store';

const globalNavMountElem = document.getElementById('global-nav-mount');

if (globalNavMountElem) {
	const app = createApp({
		name: 'GlobalNavApp',
	});
	app.component('global-nav', GlobalNav);
	app.use(store);	
	app.component('global-search-popup', GlobalSearchPopup);
	app.mount(globalNavMountElem);
}